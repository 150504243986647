import {createApiRef, DiscoveryApi, FetchApi} from "@backstage/core-plugin-api";
import {pluginId, SecretObject} from "@internal/backstage-plugin-secret-sharing-common";

export const secretSharingApiRef = createApiRef<SecretSharingApi>({
    id: pluginId,
});

// Defines API interface.
export interface SecretSharingApi {
    createSecret(secret: any): Promise<Response>;

    getSecrets(): Promise<Response>;

    getSecret(id: any): Promise<Response>;
}

// API interface implementation.
export class SecretSharingClient implements SecretSharingApi {
    private readonly discoveryApi: DiscoveryApi;
    private readonly fetchApi: FetchApi;

    constructor(options: {
        discoveryApi: DiscoveryApi;
        fetchApi: FetchApi;
    }) {
        this.discoveryApi = options.discoveryApi;
        this.fetchApi = options.fetchApi;
    }

    async createSecret(secret: SecretObject): Promise<Response> {
        const baseUrl = await this.discoveryApi.getBaseUrl(pluginId);
        return await this.fetchApi
            .fetch(`${baseUrl}/`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(secret)
            });
    }

    async getSecrets(): Promise<Response> {
        const baseUrl = await this.discoveryApi.getBaseUrl(pluginId);
        return await this.fetchApi
            .fetch(`${baseUrl}/`);
    }

    async getSecret(id: number): Promise<Response> {
        const baseUrl = await this.discoveryApi.getBaseUrl(pluginId);
        return await this.fetchApi
            .fetch(`${baseUrl}/${id}`);
    }
}
