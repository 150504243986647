// App.tsx is for defining application routes and adding components.

import * as React from "react";
import {Route} from "react-router-dom";
import {apiDocsPlugin} from "@backstage/plugin-api-docs";
import {CatalogEntityPage, CatalogIndexPage, catalogPlugin,} from "@backstage/plugin-catalog";
import {ScaffolderPage, scaffolderPlugin} from "@backstage/plugin-scaffolder";
import {orgPlugin} from "@backstage/plugin-org";
import {SearchPage} from "@backstage/plugin-search";
import {TechDocsIndexPage, techdocsPlugin, TechDocsReaderPage,} from "@backstage/plugin-techdocs";
import {TechDocsAddons} from "@backstage/plugin-techdocs-react";
import {LightBox, ReportIssue} from "@backstage/plugin-techdocs-module-addons-contrib";
import {UserSettingsPage} from "@backstage/plugin-user-settings";
import {apis} from "./apis";
import {entityPage} from "./components/catalog/EntityPage";
import {searchPage} from "./components/search/SearchPage";
import {RBACRoot} from "@spotify/backstage-plugin-rbac";
import {Root} from "./components/Root";

import {AlertDisplay, IdentityProviders, OAuthRequestDialog, SignInPage} from "@backstage/core-components";
import {createApp} from "@backstage/app-defaults";
import {AppRouter, FlatRoutes, SignInPageProps} from "@backstage/core-app-api";
import {CatalogGraphPage} from "@backstage/plugin-catalog-graph";

import {configApiRef, githubAuthApiRef, oktaAuthApiRef, useApi} from "@backstage/core-plugin-api";
import {HomepageCompositionRoot, VisitListener} from "@backstage/plugin-home";
import {HomePage} from "./components/home/HomePage";
import {SoundcheckRoutingPage} from "@spotify/backstage-plugin-soundcheck";
import {SecretSharingPage} from "@internal/backstage-plugin-secret-sharing";
import {FireChiefPage} from "@internal/firechief";
import {Mermaid} from "backstage-plugin-techdocs-addon-mermaid";

// Sign-in providers for Local Dev environments
const localDevAuth: IdentityProviders = [
    "guest",
    {
        id: "github-auth-provider",
        title: "GitHub",
        message: "Sign in using GitHub",
        apiRef: githubAuthApiRef,
    },
];

// Configurable sign-in page with default provider
function AuthPage(props: SignInPageProps, additionalProviders: IdentityProviders) {
    const oktaAuth = {
        id: "okta-auth-provider",
        title: "OKTA",
        message: "You must have a GitHub account with an associated jackhenry.com or jhacorp.com email.",
        apiRef: oktaAuthApiRef,
    };

    const isDev = window.location.href.includes("dev");
    return <SignInPage
        {...props}
        auto
        align="center"
        title={isDev ? "Warning: This environment is for development only." : ""}
        providers={[oktaAuth, ...additionalProviders]}
    />;
}

const app = createApp({
    components: {
        SignInPage: props => {
            const configApi = useApi(configApiRef);
            const isLocalDev = configApi.getString("auth.environment") === "development";
            return AuthPage(props, isLocalDev ? localDevAuth : []);
        },
    },
    apis,
    bindRoutes({bind}) {
        bind(catalogPlugin.externalRoutes, {
            createComponent: scaffolderPlugin.routes.root,
            viewTechDoc: techdocsPlugin.routes.docRoot,
            createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
        });
        bind(apiDocsPlugin.externalRoutes, {
            // registerApi: catalogImportPlugin.routes.importPage,
        });
        bind(scaffolderPlugin.externalRoutes, {
            // registerComponent: catalogImportPlugin.routes.importPage,
            viewTechDoc: techdocsPlugin.routes.docRoot,
        });
        bind(orgPlugin.externalRoutes, {
            catalogIndex: catalogPlugin.routes.catalogIndex,
        });
    },
});

const routes = (
    <FlatRoutes>
        <Route path="/" element={<HomepageCompositionRoot/>}>
            <HomePage/>
        </Route>;
        <Route path="/catalog" element={<CatalogIndexPage initiallySelectedFilter="all"/>}/>
        <Route
            path="/catalog/:namespace/:kind/:name"
            element={<CatalogEntityPage/>}
        >
            {entityPage}
        </Route>
        <Route path="/search" element={<SearchPage/>}>
            {searchPage}
        </Route>
        <Route path="/docs" element={<TechDocsIndexPage initialFilter="all"/>}/>
        <Route
            path="/docs/:namespace/:kind/:name/*"
            element={<TechDocsReaderPage/>}
        >
            {/*TODO: This should share with EntityPage.tsx so changes don't happen in two places.*/}
            <TechDocsAddons>
                <ReportIssue/>
                <LightBox/>
                <Mermaid lightConfig={{theme: "forest", themeVariables: {lineColor: "#000000"}}}
                    darkConfig={{theme: "forest", themeVariables: {lineColor: "#FFFFFF"}}}/>
            </TechDocsAddons>
        </Route>
        <Route path="/catalog-graph" element={<CatalogGraphPage/>}/>
        <Route path="/settings" element={<UserSettingsPage/>}/>

        <Route
            path="/soundcheck"
            element={<SoundcheckRoutingPage title="Soundcheck"/>}
        />
        <Route path="/rbac" element={<RBACRoot/>}/>
        <Route path="/secret-sharing" element={<SecretSharingPage/>}/>
        <Route path="/firechief" element={<FireChiefPage/>}/>

        <Route path="/create" element={<ScaffolderPage/>}/>

        {/* <Route path="/api-docs" element={<ApiExplorerPage/>}/>*/}
        {/* <Route*/}
        {/*    path="/tech-radar"*/}
        {/*    element={<TechRadarPage width={1500} height={800}/>}*/}
        {/*/ >*/}
        {/* <Route*/}
        {/*    path="/catalog-import"*/}
        {/*    element={*/}
        {/*        <RequirePermission permission={catalogEntityCreatePermission}>*/}
        {/*            <CatalogImportPage/>*/}
        {/*        </RequirePermission>*/}
        {/*    }*/}
        {/*/ >*/}
    </FlatRoutes>
);

export default app.createRoot(
    <>
        <AlertDisplay/>
        <OAuthRequestDialog/>
        <AppRouter>
            <VisitListener/>
            <Root>{routes}</Root>
        </AppRouter>
    </>,
);
