import {createPermission} from "@backstage/plugin-permission-common";

// Defines a number of things, including the base url of the backend API
export const pluginId = "secret-sharing";

export const secretCreatePermission = createPermission({
    name: `${pluginId}.secret.create`,
    attributes: {action: "create"},
});

export const secretReadPermission = createPermission({
    name: `${pluginId}.secret.read`,
    attributes: {action: "read"},
});

/**
 * List of all secret sharing permissions
 *
 * @public
 */
export const secretSharingPermissions = [secretCreatePermission, secretReadPermission];
