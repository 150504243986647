import {
    createApiRef,
    DiscoveryApi,
    FetchApi,
    IdentityApi
} from "@backstage/core-plugin-api";
import {pluginId} from "./routes";

export const FireChiefApiRef = createApiRef<FireChiefApi>({
    id: pluginId,
});

export interface FireChiefApi {
    fetchFireFighters(): Promise<[any]>;
    fetchProfile(): Promise<any>;
    fetchMembers(): Promise<any>;
    fetchIsFireFighter(): Promise<any>;
    registerAsFireFighter(): Promise<any>;
    deregisterAsFireFighter(): Promise<any>;
}

export class FireChiefClient {
    private readonly discoveryApi: DiscoveryApi;
    private readonly fetchApi: FetchApi;
    private readonly identityApi: IdentityApi;

    constructor(options: {
        discoveryApi: DiscoveryApi;
        fetchApi: FetchApi;
        identityApi: IdentityApi;
    }) {
        this.discoveryApi = options.discoveryApi;
        this.fetchApi = options.fetchApi;
        this.identityApi = options.identityApi;
    }

    async fetchProfile(): Promise<any> {
        return await this.identityApi.getProfileInfo();
    }

    async fetchIsFireFighter(): Promise<any> {
        const baseUrl = await this.discoveryApi.getBaseUrl(pluginId);
        return await this.fetchApi.fetch(`${baseUrl}/banno/isfirefighter`)
            .then(response => response.json());
    }

    async fetchFireFighters(): Promise<any> {
        const baseUrl = await this.discoveryApi.getBaseUrl(pluginId);
        return await this.fetchApi.fetch(`${baseUrl}/banno/firefighters`)
            .then(response => response.json());
    }

    async registerAsFireFighter(): Promise<any> {
        const baseUrl = await this.discoveryApi.getBaseUrl(pluginId);
        return await this.fetchApi.fetch(`${baseUrl}/banno/registerasfirefighter`, {
            method: "POST"
        })
            .then(response => response.json());
    }

    async deregisterAsFireFighter(): Promise<any> {
        const baseUrl = await this.discoveryApi.getBaseUrl(pluginId);
        return await this.fetchApi.fetch(`${baseUrl}/banno/deregisterasfirefighter`, {
            method: "POST"
        })
            .then(response => response.json());
    }

}
