import {ScmAuth, ScmIntegrationsApi, scmIntegrationsApiRef,} from "@backstage/integration-react";
import {
    AnyApiFactory,
    configApiRef,
    createApiFactory,
    discoveryApiRef,
    errorApiRef,
    githubAuthApiRef,
    identityApiRef,
    oauthRequestApiRef,
} from "@backstage/core-plugin-api";
import {visitsApiRef, VisitsWebStorageApi} from "@backstage/plugin-home";
import {GithubAuth} from "@backstage/core-app-api";

export const apis: AnyApiFactory[] = [
    // API for source code management integration
    createApiFactory({
        api: scmIntegrationsApiRef,
        deps: {configApi: configApiRef},
        factory: ({configApi}) => ScmIntegrationsApi.fromConfig(configApi),
    }),
    // API for tracking page visits
    createApiFactory({
        api: visitsApiRef,
        deps: {
            identityApi: identityApiRef,
            errorApi: errorApiRef
        },
        factory: ({identityApi, errorApi}) => VisitsWebStorageApi.create({identityApi, errorApi}),
    }),
    // API that overrides OAuth application scopes on user login
    createApiFactory({
        api: githubAuthApiRef,
        deps: {
            discoveryApi: discoveryApiRef,
            oauthRequestApi: oauthRequestApiRef,
            configApi: configApiRef,
        },
        factory: ({discoveryApi, oauthRequestApi, configApi}) =>
            GithubAuth.create({
                configApi,
                discoveryApi,
                oauthRequestApi,
                defaultScopes: ["read:user", "read:org", "repo"],
                environment: configApi.getOptionalString("auth.environment"),
            }),
    }),
    ScmAuth.createDefaultApiFactory(),
];
